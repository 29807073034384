.popup-content {
    background: #D9D9D9;
    border: 2px solid black;
    border-radius: 10px;
    height: 700px;
    width: 400px;
    position: relative;
    text-align: center;
}

.redboard {
    background: #AC2B37;
    color: white;
    padding: 5px;
    border-radius: 5px;
    text-align: center;
}

.redboard h2 {
    font-family: 'Georgia', serif;
    font-size: 22px;
}

.circle-button {
    border: none;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    background-color: transparent;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
}

.circle-button-image {
    border-radius: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.whiteboard {
    background: white;
    color: black;
    padding: 5px;
    border-radius: 5px;
    text-align: left;
    margin-top: 10px;
}

.header-row {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.WPI-logo {
    width: 50px;
    height: auto;
    margin-right: 10px;
}

.whiteboard h3 {
    margin-left: 5px;
}

.whiteboard p {
    margin-left: 5px;
}
.whiteboard h4 {
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 5px;
}
